import 'string-format-js/format.min.js';

import browser from './shared/all/browser';
import bootstrap from './bootstrap';
import MDASentry from './shared/all/mda-sentry';
import { getTenantName, createScript, checkQvalonDomain } from './shared/all/utils';

const isValidBrowser = browser.satisfies({
	chrome: '>44',
	firefox: '>21',
	opera: '>22',
	'android browser': '>4',
});

// Локально можно тут изменить пропс и включится либка
const defaultToolsInclusion = {
	ga: false,
	walkme: false,
	voximplantDebugInfo: false,
	userpilot: false,
	segment: false,
	sentry: false,
	usetiful: false,
	metrika: false,
	dashly: false,
	amplitude: false,
	usedesk: false,
	getResponse: false,
	theme: 'mdAudit',
	refiner: false,
	cux: false,
	metrikaInclude: '',
	metrikaExclude: '',
};

if (!isValidBrowser && isValidBrowser !== undefined) {
	const errorView = `<div class="browser-support-error">
					  <div class="browser-support-error_content">
					  <img class="browser-support-error_logo" src="img/mdaudit-logo-new.png" alt="MD Audit" />
					  <div class="browser-support-error_title">Ваш браузер не поддерживается</div>
					  <div class="browser-support-error_desc">Пожалуйста, обновите Ваш браузер или скачайте <a href="https://www.google.com/intl/ru_ALL/chrome/" target="_blank">Google Chrome</a> для корректной работы приложения.</div>
					</div>`;
	const body = document.body;
	body.innerHTML = errorView;
} else {
	checkIfTenantExists().then(result => {
		if (result.ok) {
			getToolsInclusion().then(tools => {
				const toolsInclusion = !tools || tools.error ? defaultToolsInclusion : tools;
				const tenantName = getTenantName();

				if (tenantName === 'edayandex') {
					toolsInclusion.metrika = true;
				}

				if (
					(toolsInclusion.metrikaInclude && toolsInclusion.metrikaInclude.split(',').includes(tenantName)) ||
					(toolsInclusion.metrikaExclude && !toolsInclusion.metrikaExclude.split(',').includes(tenantName))
				) {
					includePartialMetrika();
				}

				if (toolsInclusion.ga) includeGA();
				if (toolsInclusion.walkme) includeWalkme();
				if (toolsInclusion.userpilot) includeUserpilot();
				if (toolsInclusion.segment) includeSegment();
				if (toolsInclusion.sentry) MDASentry.init();
				if (toolsInclusion.usetiful) includeUsetiful();
				if (toolsInclusion.metrika) includeMetrika();
				if (toolsInclusion.usedesk) includeUsedesk(toolsInclusion.theme);
				if (toolsInclusion.getResponse) includeGetResponse();
				if (toolsInclusion.refiner) includeRefiner();
				if (toolsInclusion.cux) includeCux();

				bootstrap.vue(null, toolsInclusion);
			});
			return;
		}

		let error = { status: result.status };

		if (result.headers.get('x-error-message') && result.headers.get('x-error-message').includes('Tenant not found')) {
			error.message = 'system_not_found';
		} else {
			error.message = 'system_temporarily_disabled';
			error.description = 'service_not_available';
		}

		bootstrap.vue(error);
	});
}

function checkIfTenantExists() {
	const config = { method: 'POST', headers: { 'Content-Type': 'application/json;charset=UTF-8' } };
	return fetch('api/xauth/existTenant', config);
}

function getToolsInclusion() {
	return fetch('/tools-inclusion.json', {
		headers: { 'Content-Type': 'application/json;charset=UTF-8', 'Cache-Control': 'no-cache, no-store, must-revalidate' },
	})
		.then(result => result.json())
		.catch(error => ({ error }));
}

function includeGA() {
	const ga = createScript({ src: 'https://www.googletagmanager.com/gtag/js?id=UA-136428131-1', async: true });
	document.body.appendChild(ga);

	window.dataLayer = window.dataLayer || [];
	window.gtag = function () {
		window.dataLayer.push(arguments);
	};

	gtag('js', new Date());
	gtag('config', 'UA-136428131-1', {
		send_page_view: false,
	});
}

function includeWalkme() {
	const walkme = createScript({
		src: 'https://cdn.walkme.com/users/064b6c0c58934e82bd8443cd27007400/walkme_064b6c0c58934e82bd8443cd27007400_https.js',
		async: true,
	});
	const s = document.getElementsByTagName('script')[0];

	s.parentNode.insertBefore(walkme, s);

	window._walkmeConfig = {
		smartLoad: true,
	};
}

function includeUserpilot() {
	document.head.appendChild(createScript({ src: 'https://js.userpilot.io/60yi20f8/latest.js' }));
}

function includeSegment() {
	document.body.appendChild(
		createScript({
			code: '!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";analytics.load("fZ88FEwHqYgYCrZniNQT3dWLpjuQnJhE");}}();',
		})
	);
}

function includeUsetiful() {
	document.body.appendChild(
		createScript({
			code: "(function (w, d, s) {var a = d.getElementsByTagName('head')[0];var r = d.createElement('script');r.async = 1;r.src = s;r.setAttribute('id', 'usetifulScript');r.dataset.token = \"14ea1e77ad05bc439dda8963efaa43ee\";a.appendChild(r);})(window, document, \"https://www.usetiful.com/dist/usetiful.js\");",
		})
	);
}

function includeMetrika() {
	document.body.prepend(
		createScript({
			code: `
				(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
				m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
				(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

				ym(89881176, "init", {
				clickmap:true,
				trackLinks:true,
				accurateTrackBounce:true,
				webvisor:false,
				defer: true
				});
			`,
		})
	);
}

function includePartialMetrika() {
	document.body.prepend(
		createScript({
			code: `
				(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
				m[i].l=1*new Date();
				for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
				k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
				(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

				ym(51206243, "init", {
					clickmap:true,
					trackLinks:true,
					accurateTrackBounce:true,
					webvisor:true
				});
			`,
		})
	);
}

function includeUsedesk(theme) {
	const isQvalonDomain = checkQvalonDomain(theme);
	const widgetId = isQvalonDomain ? '162772_36703' : '162772_36699';
	const usedesk = createScript({ src: `https://lib.usedesk.ru/secure.usedesk.ru/widget_${widgetId}.js`, async: true });

	document.body.appendChild(usedesk);
}

function includeGetResponse() {
	document.head.appendChild(
		createScript({
			code: `(function(m, o, n, t, e, r, _){m['__GetResponseAnalyticsObject'] = e;m[e] = m[e] || function() {(m[e].q = m[e].q || []).push(arguments)};r = o.createElement(n);_ = o.getElementsByTagName(n)[0];r.async = 1;r.src = t;r.setAttribute('crossorigin', 'use-credentials');_.parentNode .insertBefore(r, _);})(window, document, 'script', 'https://ga.getresponse.com/script/64e6f2fd-f213-475b-be72-3b3140726b62/ga.js', 'GrTracking');`,
		})
	);
}

function includeRefiner() {
	document.body.appendChild(
		createScript({
			code: `window._refinerQueue = window._refinerQueue || []; function _refiner(){_refinerQueue.push(arguments);} _refiner('setProject', '8c9668f0-1184-11ed-9721-ed3eabb638df'); (function(){var a=document.createElement("script");a.type="text/javascript";a.async=!0;a.src="https://js.refiner.io/v001/client.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)})();`,
		})
	);
}

function includeCux() {
	document.body.appendChild(
		createScript({
			code: `(function(c,u,x,i,o){c._cuxSettings=c._cuxSettings||{id:o};var e=u.createElement(x),t=u.getElementsByTagName(x)[0];e.src=i;e.async=true;t.parentNode.insertBefore(e,t);c._cux_q=[];c._cux=c._cux||{send:function(n,p){c._cux_q.push({n:n,p:p,t:new Date()})}};})(window, document, 'script', '//dc.cux.io/analyzer.js', '1179331882.0.77654900.1668774627.63777ae3bd96b');`,
		})
	);
}
