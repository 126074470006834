export const getTenantName = (): string => {
	const splittedHostname = window.location.hostname.split('.');
	return splittedHostname.length > 1 ? splittedHostname[0] : '';
};

export const processArrayInChunks = <T extends any, I extends unknown>(
	arr: T[],
	{ onChunk, chunkSize }: { onChunk: (chunk: T[], prevValue: I) => void; chunkSize?: number },
	initialValue?: I
): any => {
	return process(arr, initialValue);

	function process(arr: T[], prevValue) {
		chunkSize = chunkSize || 500;

		const chunksAmount = Math.ceil(arr.length / chunkSize);
		const currentChunk = arr.slice(0, chunkSize);

		prevValue = onChunk(currentChunk, prevValue);

		if (chunksAmount > 1) {
			return new Promise(resolve => {
				setTimeout(() => {
					const nextChunks = arr.slice(chunkSize);
					process(nextChunks, prevValue).then(resolve);
				});
			});
		}

		return Promise.resolve(prevValue);
	}
};

export const abbreviateNumber = (number, decPlaces = 0) => {
	// 2 decimal places => 100, 3 => 1000, etc
	decPlaces = Math.pow(10, decPlaces);

	// Enumerate number abbreviations
	const abbrev = ['k', 'm', 'b', 't'];

	// Go through the array backwards, so we do the largest first
	for (let i = abbrev.length - 1; i >= 0; i--) {
		// Convert array index to "1000", "1000000", etc
		const size = Math.pow(10, (i + 1) * 3);

		// If the number is bigger or equal do the abbreviation
		if (size <= number) {
			// Here, we multiply by decPlaces, round, and then divide by decPlaces.
			// This gives us nice rounding to a particular decimal place.
			number = Math.round((number * decPlaces) / size) / decPlaces;

			// Handle special case where we round up to the next abbreviation
			if (number == 1000 && i < abbrev.length - 1) {
				number = 1;
				i++;
			}

			// Add the letter for the abbreviation
			number += abbrev[i];

			// We are done... stop
			break;
		}
	}

	return number;
};

export const encodeRFC3986URIComponent = (str: string) => {
	return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
};

export const shortenUserDisplayName = (displayName: string) => {
	const [last, first] = displayName.split(' ');
	const initial = first ? ` ${first[0].toUpperCase()}.` : '';
	return `${last}${initial}`;
};

export const createScript = (opts: { src?: string; code?: string; async?: boolean }) => {
	const script = document.createElement('script');
	script.type = 'text/javascript';

	if (!opts.src && !opts.code) return script;

	if (opts.src) {
		script.src = opts.src;
		script.async = opts.async || false;
	} else if (opts.code) {
		script.appendChild(document.createTextNode(opts.code));
	}

	return script;
};

export const checkQvalonDomain = (theme: 'qvalon' | 'mdAudit' | 'byDomain'): boolean => {
	if (theme === 'qvalon') return true;
	if (theme === 'mdAudit') return false;

	const hostnameSplittedAndReversed = location.hostname.split('.').reverse();
	const secondLevelDomain = hostnameSplittedAndReversed[1] || '';

	return secondLevelDomain.includes('qvalon');
};
