const bootstrap = {
	angular(options) {
		return import(/* webpackChunkName: "angular" */ './angularjs/main.js').then(module => {
			const bootstrap = module.default;
			bootstrap(options);
		});
	},
	vue(err, toolsInclusion) {
		return import(/* webpackChunkName: "vue" */ './vue/main.js').then(module => {
			return import(/* webpackChunkName: 'vue-components' */ `@/components/`).then(() => {
				const bootstrap = module.default;
				bootstrap(err, toolsInclusion);
			});
		});
	},
};

export default bootstrap;
