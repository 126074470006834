import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Vue as VueIntegration, Angular as AngularIntegration } from '@sentry/integrations';

const dsn = 'https://a80d7f5356c349a9aad6ebb89b994bb3@sentry.mdaudit.dev/185';

function getDefaultOptions() {
	const version = document.documentElement.getAttribute('version') || '_test_';
	return Object.assign({}, { dsn, tracesSampleRate: 0.5 }, version ? { release: version } : {});
}

const MDASentry = {
	init() {
		Sentry.init(
			Object.assign(getDefaultOptions(), {
				integrations: [
					new Integrations.BrowserTracing({
						beforeNavigate: context => {
							return {
								...context,
								name: location.hash,
							};
						},
					}),
				],
			})
		);
	},
	unbindAngularIntegration() {
		const client = Sentry.getCurrentHub().getClient();

		const sentryСlientOptions = Object.assign(getDefaultOptions(), {
			integrations: [...Sentry.defaultIntegrations, client.getIntegration(VueIntegration), client.getIntegration(Integrations.BrowserTracing)],
		});

		Sentry.getCurrentHub().bindClient(new Sentry.BrowserClient(sentryСlientOptions));
	},
	bindAngularIntegration(angular) {
		const client = Sentry.getCurrentHub().getClient();

		const sentryСlientOptions = Object.assign(getDefaultOptions(), {
			integrations: [
				...Sentry.defaultIntegrations,
				client.getIntegration(VueIntegration),
				new AngularIntegration({ angular }),
				client.getIntegration(Integrations.BrowserTracing),
			],
		});

		Sentry.getCurrentHub().bindClient(new Sentry.BrowserClient(sentryСlientOptions));
	},
	bindVueIntegration(Vue) {
		const client = Sentry.getCurrentHub().getClient();

		const sentryСlientOptions = Object.assign(getDefaultOptions(), {
			integrations: [
				...Sentry.defaultIntegrations,
				new VueIntegration({ Vue, logErrors: true }),
				client.getIntegration(Integrations.BrowserTracing),
			],
		});

		Sentry.getCurrentHub().bindClient(new Sentry.BrowserClient(sentryСlientOptions));
	},
	getSentry() {
		return Sentry;
	},
};

export default MDASentry;
